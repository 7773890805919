<template>
  <div id="ste-contacts">
    <!--div @click="openSidebar" style="cursor: pointer;">
      <div class="side-menu-icon">
        <svg xmlns="http://www.w3.org/2000/svg" width="36.729" height="26.64" viewBox="0 0 36.729 26.64">
          <g id="Gruppe_3" data-name="Gruppe 3" transform="translate(-1005.9 -528.459)">
            <g id="Gruppe_698" data-name="Gruppe 698" transform="translate(1020.353 526)">
              <g id="Gruppe_1" data-name="Gruppe 1" transform="translate(0 0)">
                <path id="Pfad_266" data-name="Pfad 266" d="M16.2,11.815a5.907,5.907,0,1,0-5.91-5.909,5.907,5.907,0,0,0,5.91,5.909m0-10.558a4.65,4.65,0,1,1-4.652,4.649A4.65,4.65,0,0,1,16.2,1.257" transform="translate(-5.114 2.559)" fill="#848484" stroke="#848484" stroke-width="0.2"/>
                <path id="Pfad_267" data-name="Pfad 267" d="M18.446,25.328h-2.1a.627.627,0,0,0-.482.226,6.226,6.226,0,0,1-9.553,0,.627.627,0,0,0-.482-.226h-2.1A3.734,3.734,0,0,0,0,29.057v5.078a.629.629,0,0,0,.629.629H21.548a.629.629,0,0,0,.629-.628V29.057a3.734,3.734,0,0,0-3.731-3.73m2.474,8.179H1.258V29.057a2.474,2.474,0,0,1,2.474-2.474H5.544a7.48,7.48,0,0,0,11.088,0h1.812a2.474,2.474,0,0,1,2.474,2.474Z" transform="translate(0 -10.029)" fill="#848484" stroke="#848484" stroke-width="0.2"/>
              </g>
            </g>
            <rect id="Rechteck_1" data-name="Rechteck 1" width="6" height="8" transform="translate(1020 544)" fill="#fff"/>
            <rect id="Rechteck_2" data-name="Rechteck 2" width="5" height="5" transform="translate(1025 547)" fill="#fff"/>
            <rect id="Rechteck_3" data-name="Rechteck 3" width="6" height="8" transform="translate(1018 541)" fill="#fff"/>
            <g id="Gruppe_698-2" data-name="Gruppe 698" transform="translate(1006 530.265)">
              <g id="Gruppe_2" data-name="Gruppe 2">
                <path id="Pfad_266-2" data-name="Pfad 266" d="M16.2,11.815a5.907,5.907,0,1,0-5.91-5.909,5.907,5.907,0,0,0,5.91,5.909m0-10.558a4.65,4.65,0,1,1-4.652,4.649A4.65,4.65,0,0,1,16.2,1.257" transform="translate(-5.114 2.559)" fill="#848484" stroke="#848484" stroke-width="0.2"/>
                <path id="Pfad_267-2" data-name="Pfad 267" d="M18.446,25.328h-2.1a.627.627,0,0,0-.482.226,6.226,6.226,0,0,1-9.553,0,.627.627,0,0,0-.482-.226h-2.1A3.734,3.734,0,0,0,0,29.057v5.078a.629.629,0,0,0,.629.629H21.548a.629.629,0,0,0,.629-.628V29.057a3.734,3.734,0,0,0-3.731-3.73m2.474,8.179H1.258V29.057a2.474,2.474,0,0,1,2.474-2.474H5.544a7.48,7.48,0,0,0,11.088,0h1.812a2.474,2.474,0,0,1,2.474,2.474Z" transform="translate(0 -10.029)" fill="#848484" stroke="#848484" stroke-width="0.2"/>
              </g>
            </g>
          </g>
        </svg>
      </div>
      <div class="side-menu-text">Ansprechpartner</div>
    </div-->
    <div @click="closeSidebar" class="ste-ctcts-opacity-frame" :class="{'sidebarIsOpen': isOpen }"></div>
    <Sidebar class="ste-ctcts-sidebar-frame" :class="{'sidebarIsOpen': isOpen }" ref="childComponent" @child-clicked="closeSidebar"></Sidebar>
  </div>
</template>

<script>
import Sidebar from './components/Sidebar.vue'

export default {
  name: 'App',
  components: {
    Sidebar
  },
  data() {
    return {
      isOpen: false
    }
  },
  methods: {
    openSidebar () {
      this.isOpen = true
      this.$nextTick(() => {
        this.$refs.childComponent?.loadContacts()
      });
    },
    closeSidebar () {
      this.isOpen = false
    },
  },
  mounted() {
    // Füge einen Event-Listener zum externen Button hinzu
    const externalButton = document.getElementById('open-ste-sitebar');
    if (externalButton) {
      externalButton.addEventListener('click', this.openSidebar);
    }
  },
  beforeDestroy() {
    // Entferne den Event-Listener beim Zerstören der Komponente
    const externalButton = document.getElementById('open-ste-sitebar');
    if (externalButton) {
      externalButton.removeEventListener('click', this.openSidebar);
    }
  },
}
</script>

<style>
#app {
  font-family: Avenir Medium, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
.ste-ctcts-sidebar-frame {
  width: 600px;
  max-width: 100%;
  min-width: 320px;
  height: 100%;
  background-color: #ffffff;
  box-shadow: -2px 0px 20px 0px rgba(0,0,0,0.15);
  position: fixed;
  top: 0;
  right: -630px;
  z-index: 9002;
  transition: right 0.3s ease-out;
}
.ste-ctcts-sidebar-frame.sidebarIsOpen {
  right: 0;
}
.ste-ctcts-opacity-frame {
  width: 0;
  height: 0;
  background-color: #000000;
  opacity: 0;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9001;
  transition: opacity 0.3s ease-out;
}
.ste-ctcts-opacity-frame.sidebarIsOpen {
  width: 100%;
  height: 100%;
  opacity: 0.65;

}


</style>
