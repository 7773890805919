<template>
<div class="ste-ctcts-contacts-list">
  <div v-if="contacts && contacts.length < 1" class="ste-ctcts-no-contacts">
    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="111" height="77" viewBox="0 0 111 77">
      <defs>
        <clipPath id="clip-path">
          <rect id="Rechteck_16" data-name="Rechteck 16" width="111" height="77" fill="none" stroke="#c9c9c9" stroke-width="2.5"/>
        </clipPath>
        <clipPath id="clip-path-2">
          <path id="Pfad_7" data-name="Pfad 7" d="M47.587,29.046a32.385,32.385,0,0,1-3.565,11.513h24.9V37.681a8.66,8.66,0,0,0-8.635-8.635ZM45.338,12.834A32.534,32.534,0,0,1,47.817,25.3c0,.355-.042.7-.054,1.05a8.574,8.574,0,0,0,12.526-7.67,8.611,8.611,0,0,0-14.951-5.851" transform="translate(-44.022 -10.05)" fill="none" stroke="#c9c9c9" stroke-width="2.5"/>
        </clipPath>
        <clipPath id="clip-path-4">
          <path id="Pfad_10" data-name="Pfad 10" d="M9.635,29.046A8.66,8.66,0,0,0,1,37.681v2.878H25.9a32.424,32.424,0,0,1-3.565-11.513Zm0-10.362a8.574,8.574,0,0,0,12.526,7.67c-.013-.353-.054-.695-.054-1.05a32.534,32.534,0,0,1,2.479-12.47A8.611,8.611,0,0,0,9.635,18.685" transform="translate(-1 -10.05)" fill="none" stroke="#c9c9c9" stroke-width="2.5"/>
        </clipPath>
      </defs>
      <g id="Gruppe_22" data-name="Gruppe 22" transform="translate(-1092 -294)">
        <g id="Gruppe_13" data-name="Gruppe 13" transform="translate(1092 294)">
          <g id="Gruppe_12" data-name="Gruppe 12" clip-path="url(#clip-path)">
            <ellipse id="Ellipse_1" data-name="Ellipse 1" cx="33" cy="32.5" rx="33" ry="32.5" transform="translate(23 2)" fill="none" stroke="#c9c9c9" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
            <path id="Pfad_5" data-name="Pfad 5" d="M58.7,39.559,46.845,27.7a32.751,32.751,0,0,1-8.14,8.142L50.558,47.7A5.757,5.757,0,0,0,58.7,39.559Z" transform="translate(35.563 25.455)" fill="none" stroke="#c9c9c9" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
          </g>
        </g>
        <g id="Gruppe_15" data-name="Gruppe 15" transform="translate(1176.47 313.284)">
          <g id="Gruppe_14" data-name="Gruppe 14" clip-path="url(#clip-path-2)">
            <circle id="Ellipse_2" data-name="Ellipse 2" cx="8.635" cy="8.635" r="8.635" transform="translate(-1.002 0)" fill="none" stroke="#c9c9c9" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
            <path id="Pfad_6" data-name="Pfad 6" d="M39,28.585a8.66,8.66,0,0,1,8.635-8.635H64.9a8.66,8.66,0,0,1,8.635,8.635v2.878H39Z" transform="translate(-48.636 -0.954)" fill="none" stroke="#c9c9c9" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
          </g>
        </g>
        <g id="Gruppe_17" data-name="Gruppe 17" transform="translate(1092 294)">
          <g id="Gruppe_16" data-name="Gruppe 16" clip-path="url(#clip-path)">
            <path id="Pfad_8" data-name="Pfad 8" d="M47.817,25.3a32.517,32.517,0,0,0-2.479-12.469,8.657,8.657,0,1,1,2.425,13.516C47.775,26,47.817,25.658,47.817,25.3ZM68.925,37.681a8.66,8.66,0,0,0-8.635-8.635h-12.7a32.424,32.424,0,0,1-3.565,11.513h24.9Z" transform="translate(40.449 9.234)" fill="none" stroke="#c9c9c9" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
          </g>
        </g>
        <g id="Gruppe_19" data-name="Gruppe 19" transform="translate(1093.919 313.284)">
          <g id="Gruppe_18" data-name="Gruppe 18" clip-path="url(#clip-path-4)">
            <circle id="Ellipse_3" data-name="Ellipse 3" cx="8.635" cy="8.635" r="8.635" transform="translate(8.635 0)" fill="none" stroke="#c9c9c9" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
            <path id="Pfad_9" data-name="Pfad 9" d="M1,28.585A8.66,8.66,0,0,1,9.635,19.95H26.9a8.66,8.66,0,0,1,8.635,8.635v2.878H1Z" transform="translate(-1 -0.954)" fill="none" stroke="#c9c9c9" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
          </g>
        </g>
        <g id="Gruppe_21" data-name="Gruppe 21" transform="translate(1092 294)">
          <g id="Gruppe_20" data-name="Gruppe 20" clip-path="url(#clip-path)">
            <path id="Pfad_11" data-name="Pfad 11" d="M22.107,25.3c0,.353.042.7.054,1.048a8.525,8.525,0,0,1-3.891.967,8.635,8.635,0,1,1,6.317-14.483A32.517,32.517,0,0,0,22.107,25.3ZM9.635,29.046A8.66,8.66,0,0,0,1,37.681v2.878H25.9a32.424,32.424,0,0,1-3.565-11.513Z" transform="translate(0.919 9.234)" fill="none" stroke="#c9c9c9" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
            <circle id="Ellipse_4" data-name="Ellipse 4" cx="9" cy="9" r="9" transform="translate(47 16)" fill="none" stroke="#c9c9c9" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
            <path id="Pfad_12" data-name="Pfad 12" d="M26.675,28.594v7.675H49.7v0h7.675V28.594A9.622,9.622,0,0,0,47.782,19H28.594A9.622,9.622,0,0,0,19,28.594v7.675h7.675Z" transform="translate(17.458 17.458)" fill="none" stroke="#c9c9c9" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
          </g>
        </g>
      </g>
    </svg>
    <br><br>
    Es wurde kein Kontakt zum eingegebenen Suchbegriff gefunden.
  </div>
  <div v-for="(contact) in contacts" :key='contact.id'>
    <div class="ste-ctcts-contacts"  v-if="contact.phone_numbers.length > 0 || contact.mails.length > 0" >
    <div class="ste-ctcts-contact-person">
      <div v-if="contact.firstname || contact.lastname" class="ste-ctcts-contact-name">{{ contact.firstname }} {{ contact.lastname }}</div>
      <div v-if="contact.additional_information" class="ste-ctcts-contact-add">{{ contact.additional_information }}</div>
    </div>
    <a :href="formatContactData(phone.value, 'phone')" class="ste-ctcts-contact-action" v-for="(phone) in contact.phone_numbers" :key='phone.id'>
      <svg xmlns="http://www.w3.org/2000/svg" width="16.462" height="16.463" viewBox="0 0 16.462 16.463">
        <path id="Pfad_3" data-name="Pfad 3" d="M23.951,26.936a5.977,5.977,0,0,0,3.007-3.007,5.758,5.758,0,0,0-3.578-2.982,9.55,9.55,0,0,1-1.789,1.789,5.368,5.368,0,0,1-5.368-5.368,9.533,9.533,0,0,1,1.789-1.789A5.758,5.758,0,0,0,15.03,12a5.978,5.978,0,0,0-3.007,3.007,11.246,11.246,0,0,0,11.929,11.93Z" transform="translate(-11.246 -11.25)" fill="none" stroke="#3a3a3c" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" opacity="0.603"/>
      </svg>
      {{ phone.value }}
    </a>
    <a :href="formatContactData(mail.value, 'mail')" class="ste-ctcts-contact-action" v-for="(mail) in contact.mails" :key='mail.id'>
      <svg xmlns="http://www.w3.org/2000/svg" width="18.224" height="13.5" viewBox="0 0 18.224 13.5">
        <g id="Gruppe_9" data-name="Gruppe 9" transform="translate(-930.973 -410.25)" opacity="0.603">
          <rect id="Rechteck_15" data-name="Rechteck 15" width="16" height="12" rx="2.5" transform="translate(932 411)" fill="none" stroke="#3a3a3c" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
          <path id="Pfad_4" data-name="Pfad 4" d="M619,61l7.68,6.583a.575.575,0,0,0,.749,0L635.106,61" transform="translate(313.033 351.279)" fill="none" stroke="#3a3a3c" stroke-linejoin="round" stroke-width="1.5"/>
          <line id="Linie_1" data-name="Linie 1" x1="5.707" y2="4.89" transform="translate(932.03 417.168)" fill="none" stroke="#3a3a3c" stroke-linejoin="round" stroke-width="1.5"/>
          <line id="Linie_2" data-name="Linie 2" x1="5.707" y1="4.89" transform="translate(942.432 417.168)" fill="none" stroke="#3a3a3c" stroke-linejoin="round" stroke-width="1.5"/>
        </g>
      </svg>
      {{ mail.value }}
    </a>
    </div>
  </div>
</div>
</template>

<script>
export default {
  props: ['contacts'],
  methods: {
    formatContactData (data, type) {
      if (type === 'mail') {
        return 'mailto:' + data
      } else {
        let cleanedNumber = data.replace(/[^0-9]/g, '').replace(/^0/, '49');
        return 'tel:' + `+${cleanedNumber}`;
      }
    }
  }
}
</script>


<style scoped>
.ste-ctcts-no-contacts {
  padding: 40px 40px;
  border-bottom: 3px solid #EDEDED;
  font-family: Avenir Black, sans-serif;
  text-align: center;
}
.ste-ctcts-contacts-list {
  margin-top: 30px;
  border-top: 3px solid #EDEDED;
  text-align: left;
}
.ste-ctcts-contacts {
  padding: 25px 0;
  border-bottom: 3px solid #EDEDED;
}
.ste-ctcts-contact-person {
  padding: 0 15px;
  margin-bottom: 20px;
}




</style>

<style>
.ste-ctcts-contact-name {
  font-family: Avenir Black, sans-serif;
}
.ste-ctcts-contact-action {
background-color: #EDEDED;
height: 36px;
border-left: 3px solid #0074BC;
margin-bottom: 5px;
display: flex;
align-items: center;
padding-left: 15px;
cursor: pointer;
transition: background-color 0.2s ease-out;
text-decoration: none;
color: #4C4C4C;
}
.ste-ctcts-contact-action:hover {
  background-color: #dcdcdc;
  color: #333333;
}
.ste-ctcts-contact-action svg {
  margin-right: 7px;
}
</style>