<template>
  <div class="ste-ctcts-sidebar">
    <div class="ste-ctcts-header">
      <div @click="closeSidebar" class="ste-ctcts-close-btn">
        <svg style="margin-right: 10px;" xmlns="http://www.w3.org/2000/svg" width="15.556" height="15.556" viewBox="0 0 15.556 15.556">
          <g id="Gruppe_7" data-name="Gruppe 7" transform="translate(-1018.722 -14.722)">
            <rect id="Rechteck_8" data-name="Rechteck 8" width="19" height="3" transform="translate(1020.843 14.722) rotate(45)" fill="#4c4c4c"/>
            <rect id="Rechteck_9" data-name="Rechteck 9" width="19" height="3" transform="translate(1034.278 16.843) rotate(135)" fill="#4c4c4c"/>
          </g>
        </svg>
        <span>Schließen</span>
      </div>
    </div>
    <div class="ste-ctcts-main">
      <Loader class="ste-ctcts-loader" :loading="contactsIsLoading" color="#0074BC" size="50px"></Loader>
      <transition name="ctcts-fade">
        <div class="ste-ctcts-main-frame" v-if="filterList">
          <div class="ste-ctcts-filterpage" :class="{'oneLength': activePage === 2, 'twoLength': activePage === 3 }">
            <div class="ste-ctcts-hl">Kontakt suchen</div>
            <div class="ste-ctcts-search-holder">
              <v-text-field
                  class="ste-ctcts-search-field"
                  v-model="searchQuery"
                  @keyup.enter="searchContact"
                  label="Suchbegriff ..."
                  solo
              ></v-text-field>
              <div class="search-btn" :class="{'disabled': !searchQuery || searchQuery.length < 3}" @click="searchContact">Suchen</div>
            </div>
            <br>
            <div class="ste-ctcts-hl">Nach Kategorien filtern</div>

            <template v-for="(filter, index) in filteredCategories" >
              <div v-if="filter.text === 'Firma'" :class="{'no-show': filter.sub_filters.length < 1}" class="ste-ctcts-filter-items" @click="viewSubCategories(filter.sub_filters)" :key='index'>
                <span>{{ filter.text }}</span>
                <svg id="Gruppe_768" data-name="Gruppe 768" xmlns="http://www.w3.org/2000/svg" width="9.246" height="15.401" viewBox="0 0 9.246 15.401">
                  <rect id="Rechteck_6" data-name="Rechteck 6" width="10.461" height="2.615" transform="translate(1.849 0) rotate(45)" fill="#4C4C4C"></rect>
                  <rect id="Rechteck_7" data-name="Rechteck 7" width="10.461" height="2.615" transform="translate(0 13.552) rotate(-45)" fill="#4C4C4C"></rect>
                </svg>
              </div>
            </template>

            <template v-for="(filter, index) in filteredCategories" >
              <div v-if="filter.text !== 'Firma'" :class="{'no-show': filter.sub_filters.length < 1, 'space': filter.text === 'Abteilung'}" class="ste-ctcts-filter-items" @click="viewSubCategories(filter.sub_filters)" :key='index'>
                <span>{{ filter.text }}</span>
                <svg id="Gruppe_768" data-name="Gruppe 768" xmlns="http://www.w3.org/2000/svg" width="9.246" height="15.401" viewBox="0 0 9.246 15.401">
                  <rect id="Rechteck_6" data-name="Rechteck 6" width="10.461" height="2.615" transform="translate(1.849 0) rotate(45)" fill="#4C4C4C"></rect>
                  <rect id="Rechteck_7" data-name="Rechteck 7" width="10.461" height="2.615" transform="translate(0 13.552) rotate(-45)" fill="#4C4C4C"></rect>
                </svg>
              </div>
            </template>

          </div>
          <div class="ste-ctcts-filtersubpage ste-ctcts-search-page" :class="{'oneLength': activePage === 2, 'twoLength': activePage === 3, 'no-show': pageType === 'categories' }">
            <div @click="activePage = 1" class="ste-ctcts-back-btn">
              <svg id="Gruppe_768" data-name="Gruppe 768" xmlns="http://www.w3.org/2000/svg" width="9.246" height="15.401" viewBox="0 0 9.246 15.401">
                <rect id="Rechteck_6" data-name="Rechteck 6" width="10.461" height="2.615" transform="translate(1.849 0) rotate(45)" fill="#4C4C4C;"></rect>
                <rect id="Rechteck_7" data-name="Rechteck 7" width="10.461" height="2.615" transform="translate(0 13.552) rotate(-45)" fill="#4C4C4C;"></rect>
              </svg>
              <span>Zurück</span>
            </div>
            <ContactList :contacts="searchedContacts" ></ContactList>
          </div>
          <div class="ste-ctcts-filtersubpage ste-ctcts-categories-page" :class="{'oneLength': activePage === 2, 'twoLength': activePage === 3, 'no-show': pageType === 'search' }">
            <div @click="activePage = 1" class="ste-ctcts-back-btn">
              <svg id="Gruppe_768" data-name="Gruppe 768" xmlns="http://www.w3.org/2000/svg" width="9.246" height="15.401" viewBox="0 0 9.246 15.401">
                <rect id="Rechteck_6" data-name="Rechteck 6" width="10.461" height="2.615" transform="translate(1.849 0) rotate(45)" fill="#4C4C4C;"></rect>
                <rect id="Rechteck_7" data-name="Rechteck 7" width="10.461" height="2.615" transform="translate(0 13.552) rotate(-45)" fill="#4C4C4C;"></rect>
              </svg>
              <span>Zurück</span>
            </div>
            <div class="ste-ctcts-filter-items" @click="viewFilteredContacts(subFilter.id)" v-for="(subFilter, index) in tempSubFilters " :key='index'>

              <span v-if="subFilter.value === 'Steinbach-Verwaltungsgesellschaft mbH'">{{ subFilter.value }} <br>(Energie und Automation)</span>

              <span v-else >{{ subFilter.value }}</span>

              <svg id="Gruppe_768" data-name="Gruppe 768" xmlns="http://www.w3.org/2000/svg" width="9.246" height="15.401" viewBox="0 0 9.246 15.401">
                <rect id="Rechteck_6" data-name="Rechteck 6" width="10.461" height="2.615" transform="translate(1.849 0) rotate(45)" fill="#4C4C4C"></rect>
                <rect id="Rechteck_7" data-name="Rechteck 7" width="10.461" height="2.615" transform="translate(0 13.552) rotate(-45)" fill="#4C4C4C"></rect>
              </svg>
            </div>
          </div>
          <div class="ste-ctcts-entries" :class="{'oneLength': activePage === 2, 'twoLength': activePage === 3 }">
            <div @click="activePage = 2" class="ste-ctcts-back-btn">
              <svg id="Gruppe_768" data-name="Gruppe 768" xmlns="http://www.w3.org/2000/svg" width="9.246" height="15.401" viewBox="0 0 9.246 15.401">
                <rect id="Rechteck_6" data-name="Rechteck 6" width="10.461" height="2.615" transform="translate(1.849 0) rotate(45)" fill="#4C4C4C"></rect>
                <rect id="Rechteck_7" data-name="Rechteck 7" width="10.461" height="2.615" transform="translate(0 13.552) rotate(-45)" fill="#4C4C4C"></rect>
              </svg>
              <span>Zurück</span>
            </div>
            <div v-if="address === 103" class="ste-ctcts-comp-address">
              <div class="ste-ctcts-contact-name">
                <strong>Adolf Steinbach Steinindustrie-Schotterwerke GmbH & Co. KG</strong><br>
                Strahlunger Straße 18<br>
                97616 Salz<br>
              </div><br>
              <a href="tel:+49977168877200" class="ste-ctcts-contact-action">
                <svg data-v-002fd7de="" xmlns="http://www.w3.org/2000/svg" width="16.462" height="16.463" viewBox="0 0 16.462 16.463"><path data-v-002fd7de="" id="Pfad_3" data-name="Pfad 3" d="M23.951,26.936a5.977,5.977,0,0,0,3.007-3.007,5.758,5.758,0,0,0-3.578-2.982,9.55,9.55,0,0,1-1.789,1.789,5.368,5.368,0,0,1-5.368-5.368,9.533,9.533,0,0,1,1.789-1.789A5.758,5.758,0,0,0,15.03,12a5.978,5.978,0,0,0-3.007,3.007,11.246,11.246,0,0,0,11.929,11.93Z" transform="translate(-11.246 -11.25)" fill="none" stroke="#3a3a3c" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" opacity="0.603"></path></svg>
                +49 (0) 9771 68877-200
              </a>
              <a href="mailto:felsgesteine@steinbach-gruppe.de" class="ste-ctcts-contact-action">
                  <svg data-v-002fd7de="" xmlns="http://www.w3.org/2000/svg" width="18.224" height="13.5" viewBox="0 0 18.224 13.5"><g data-v-002fd7de="" id="Gruppe_9" data-name="Gruppe 9" transform="translate(-930.973 -410.25)" opacity="0.603"><rect data-v-002fd7de="" id="Rechteck_15" data-name="Rechteck 15" width="16" height="12" rx="2.5" transform="translate(932 411)" fill="none" stroke="#3a3a3c" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"></rect><path data-v-002fd7de="" id="Pfad_4" data-name="Pfad 4" d="M619,61l7.68,6.583a.575.575,0,0,0,.749,0L635.106,61" transform="translate(313.033 351.279)" fill="none" stroke="#3a3a3c" stroke-linejoin="round" stroke-width="1.5"></path><line data-v-002fd7de="" id="Linie_1" data-name="Linie 1" x1="5.707" y2="4.89" transform="translate(932.03 417.168)" fill="none" stroke="#3a3a3c" stroke-linejoin="round" stroke-width="1.5"></line><line data-v-002fd7de="" id="Linie_2" data-name="Linie 2" x1="5.707" y1="4.89" transform="translate(942.432 417.168)" fill="none" stroke="#3a3a3c" stroke-linejoin="round" stroke-width="1.5"></line></g></svg>
                  felsgesteine@steinbach-gruppe.de
              </a>
              <a target="_blank" href="https://www.google.de/maps/place/Adolf+Steinbach+Steinindustrie-Schotterwerke+GmbH+%26+Co.+KG+%7C+Werk+Salz%2FStrahlungen/@50.2921471,10.2212253,744m/data=!3m1!1e3!4m10!1m2!2m1!1sdolf+Steinbach+Steinindustrie-Schotterwerke+GmbH+%26+Co.+KG!3m6!1s0x47a303e634889ba1:0xf80824f11a042a77!8m2!3d50.2919611!4d10.2230626!15sCjlkb2xmIFN0ZWluYmFjaCBTdGVpbmluZHVzdHJpZS1TY2hvdHRlcndlcmtlIEdtYkggJiBDby4gS0eSAQZxdWFycnngAQA!16s%2Fg%2F1vnrk0ck?entry=ttu&g_ep=EgoyMDI1MDEyMi4wIKXMDSoASAFQAw%3D%3D" class="ste-ctcts-contact-action">
                <svg xmlns="http://www.w3.org/2000/svg" width="16.14" height="19.739" viewBox="0 0 16.14 19.739">
                  <rect id="Rechteck_1" data-name="Rechteck 1" width="16.14" height="18" transform="translate(0 0.398)" fill="#fff" opacity="0"/>
                  <g id="Gruppe_1" data-name="Gruppe 1" transform="translate(0.5 -0.464)">
                    <path id="Pfad_1" data-name="Pfad 1" d="M7.759,1.418A6.343,6.343,0,0,0,2.2,10.805l4.751,8.378a.935.935,0,0,0,1.626,0l4.758-8.392A6.343,6.343,0,0,0,7.759,1.418Zm0,3.075A3.267,3.267,0,1,1,4.492,7.76,3.271,3.271,0,0,1,7.759,4.493Z" transform="translate(-0.204 -0.204)" fill="none" stroke="#878788" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
                  </g>
                </svg>
                Standort in Google Maps öffnen
              </a>
            </div>
            <div v-if="address === 104" class="ste-ctcts-comp-address">
              <div class="ste-ctcts-contact-name">
                <strong>Fertigungsgerätebau Adolf Steinbach GmbH & Co. KG</strong><br>
                Strahlunger Straße 18<br>
                97616 Salz<br>
              </div><br>
              <a href="tel:+49977168877500" class="ste-ctcts-contact-action">
                <svg data-v-002fd7de="" xmlns="http://www.w3.org/2000/svg" width="16.462" height="16.463" viewBox="0 0 16.462 16.463"><path data-v-002fd7de="" id="Pfad_3" data-name="Pfad 3" d="M23.951,26.936a5.977,5.977,0,0,0,3.007-3.007,5.758,5.758,0,0,0-3.578-2.982,9.55,9.55,0,0,1-1.789,1.789,5.368,5.368,0,0,1-5.368-5.368,9.533,9.533,0,0,1,1.789-1.789A5.758,5.758,0,0,0,15.03,12a5.978,5.978,0,0,0-3.007,3.007,11.246,11.246,0,0,0,11.929,11.93Z" transform="translate(-11.246 -11.25)" fill="none" stroke="#3a3a3c" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" opacity="0.603"></path></svg>
                +49 (0) 9771 68877-500
              </a>
              <a href="mailto:info@fgb.de" class="ste-ctcts-contact-action">
                <svg data-v-002fd7de="" xmlns="http://www.w3.org/2000/svg" width="18.224" height="13.5" viewBox="0 0 18.224 13.5"><g data-v-002fd7de="" id="Gruppe_9" data-name="Gruppe 9" transform="translate(-930.973 -410.25)" opacity="0.603"><rect data-v-002fd7de="" id="Rechteck_15" data-name="Rechteck 15" width="16" height="12" rx="2.5" transform="translate(932 411)" fill="none" stroke="#3a3a3c" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"></rect><path data-v-002fd7de="" id="Pfad_4" data-name="Pfad 4" d="M619,61l7.68,6.583a.575.575,0,0,0,.749,0L635.106,61" transform="translate(313.033 351.279)" fill="none" stroke="#3a3a3c" stroke-linejoin="round" stroke-width="1.5"></path><line data-v-002fd7de="" id="Linie_1" data-name="Linie 1" x1="5.707" y2="4.89" transform="translate(932.03 417.168)" fill="none" stroke="#3a3a3c" stroke-linejoin="round" stroke-width="1.5"></line><line data-v-002fd7de="" id="Linie_2" data-name="Linie 2" x1="5.707" y1="4.89" transform="translate(942.432 417.168)" fill="none" stroke="#3a3a3c" stroke-linejoin="round" stroke-width="1.5"></line></g></svg>
                info@fgb.de
              </a>
              <a target="_blank" href="https://www.google.de/maps/place/FGB:+Fertigungsger%C3%A4tebau+Adolf+Steinbach+GmbH+%26+Co.+KG/@50.2919298,10.2213485,744m/data=!3m2!1e3!4b1!4m6!3m5!1s0x47a303e82182d425:0x65d574d82234cf75!8m2!3d50.2919275!4d10.2230608!16s%2Fg%2F11b6vcxp9n?entry=ttu&g_ep=EgoyMDI1MDEyMi4wIKXMDSoASAFQAw%3D%3D" class="ste-ctcts-contact-action">
                <svg xmlns="http://www.w3.org/2000/svg" width="16.14" height="19.739" viewBox="0 0 16.14 19.739">
                  <rect id="Rechteck_1" data-name="Rechteck 1" width="16.14" height="18" transform="translate(0 0.398)" fill="#fff" opacity="0"/>
                  <g id="Gruppe_1" data-name="Gruppe 1" transform="translate(0.5 -0.464)">
                    <path id="Pfad_1" data-name="Pfad 1" d="M7.759,1.418A6.343,6.343,0,0,0,2.2,10.805l4.751,8.378a.935.935,0,0,0,1.626,0l4.758-8.392A6.343,6.343,0,0,0,7.759,1.418Zm0,3.075A3.267,3.267,0,1,1,4.492,7.76,3.271,3.271,0,0,1,7.759,4.493Z" transform="translate(-0.204 -0.204)" fill="none" stroke="#878788" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
                  </g>
                </svg>
                Standort in Google Maps öffnen
              </a>
            </div>
            <div v-if="address === 111" class="ste-ctcts-comp-address">
              <div class="ste-ctcts-contact-name">
                <strong>FGB: Entwicklung + Konstruktion GmbH</strong><br>
                Strahlunger Straße 18<br>
                97616 Salz<br>
              </div><br>
              <a href="tel:+49977168877600" class="ste-ctcts-contact-action">
                <svg data-v-002fd7de="" xmlns="http://www.w3.org/2000/svg" width="16.462" height="16.463" viewBox="0 0 16.462 16.463"><path data-v-002fd7de="" id="Pfad_3" data-name="Pfad 3" d="M23.951,26.936a5.977,5.977,0,0,0,3.007-3.007,5.758,5.758,0,0,0-3.578-2.982,9.55,9.55,0,0,1-1.789,1.789,5.368,5.368,0,0,1-5.368-5.368,9.533,9.533,0,0,1,1.789-1.789A5.758,5.758,0,0,0,15.03,12a5.978,5.978,0,0,0-3.007,3.007,11.246,11.246,0,0,0,11.929,11.93Z" transform="translate(-11.246 -11.25)" fill="none" stroke="#3a3a3c" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" opacity="0.603"></path></svg>
                +49 (0) 9771 68877-600
              </a>
              <a href="mailto:info@fgb.de" class="ste-ctcts-contact-action">
                <svg data-v-002fd7de="" xmlns="http://www.w3.org/2000/svg" width="18.224" height="13.5" viewBox="0 0 18.224 13.5"><g data-v-002fd7de="" id="Gruppe_9" data-name="Gruppe 9" transform="translate(-930.973 -410.25)" opacity="0.603"><rect data-v-002fd7de="" id="Rechteck_15" data-name="Rechteck 15" width="16" height="12" rx="2.5" transform="translate(932 411)" fill="none" stroke="#3a3a3c" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"></rect><path data-v-002fd7de="" id="Pfad_4" data-name="Pfad 4" d="M619,61l7.68,6.583a.575.575,0,0,0,.749,0L635.106,61" transform="translate(313.033 351.279)" fill="none" stroke="#3a3a3c" stroke-linejoin="round" stroke-width="1.5"></path><line data-v-002fd7de="" id="Linie_1" data-name="Linie 1" x1="5.707" y2="4.89" transform="translate(932.03 417.168)" fill="none" stroke="#3a3a3c" stroke-linejoin="round" stroke-width="1.5"></line><line data-v-002fd7de="" id="Linie_2" data-name="Linie 2" x1="5.707" y1="4.89" transform="translate(942.432 417.168)" fill="none" stroke="#3a3a3c" stroke-linejoin="round" stroke-width="1.5"></line></g></svg>
                info@fgb.de
              </a>
              <a target="_blank" href="https://www.google.de/maps/place/FGB:+Entwicklung+%2B+Konstruktion+GmbH/@50.2919955,10.2209171,744m/data=!3m3!1e3!4b1!5s0x47a303e8181a2ebd:0xf39680a681c8f7c1!4m6!3m5!1s0x47a3038e6bda476b:0x43cc339092c4bf1e!8m2!3d50.2919932!4d10.2226294!16s%2Fg%2F1tncrxnr?entry=ttu&g_ep=EgoyMDI1MDEyMi4wIKXMDSoASAFQAw%3D%3D" class="ste-ctcts-contact-action">
                <svg xmlns="http://www.w3.org/2000/svg" width="16.14" height="19.739" viewBox="0 0 16.14 19.739">
                  <rect id="Rechteck_1" data-name="Rechteck 1" width="16.14" height="18" transform="translate(0 0.398)" fill="#fff" opacity="0"/>
                  <g id="Gruppe_1" data-name="Gruppe 1" transform="translate(0.5 -0.464)">
                    <path id="Pfad_1" data-name="Pfad 1" d="M7.759,1.418A6.343,6.343,0,0,0,2.2,10.805l4.751,8.378a.935.935,0,0,0,1.626,0l4.758-8.392A6.343,6.343,0,0,0,7.759,1.418Zm0,3.075A3.267,3.267,0,1,1,4.492,7.76,3.271,3.271,0,0,1,7.759,4.493Z" transform="translate(-0.204 -0.204)" fill="none" stroke="#878788" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
                  </g>
                </svg>
                Standort in Google Maps öffnen
              </a>
            </div>
            <div v-if="address === 112" class="ste-ctcts-comp-address">
              <div class="ste-ctcts-contact-name">
                <strong>FGB: Präzisionsmaschinenbau Suhl Steinbach GmbH & Co. KG</strong><br>
                Gewerbegebiet Ost<br>
                Am Köhlersgehäu 16<br>
                98544 Zella-Mehlis<br>
              </div><br>
              <a href="tel:+49368246550" class="ste-ctcts-contact-action">
                <svg data-v-002fd7de="" xmlns="http://www.w3.org/2000/svg" width="16.462" height="16.463" viewBox="0 0 16.462 16.463"><path data-v-002fd7de="" id="Pfad_3" data-name="Pfad 3" d="M23.951,26.936a5.977,5.977,0,0,0,3.007-3.007,5.758,5.758,0,0,0-3.578-2.982,9.55,9.55,0,0,1-1.789,1.789,5.368,5.368,0,0,1-5.368-5.368,9.533,9.533,0,0,1,1.789-1.789A5.758,5.758,0,0,0,15.03,12a5.978,5.978,0,0,0-3.007,3.007,11.246,11.246,0,0,0,11.929,11.93Z" transform="translate(-11.246 -11.25)" fill="none" stroke="#3a3a3c" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" opacity="0.603"></path></svg>
                +49 (0) 3682 4655-0
              </a>
              <a href="mailto:info@fgb-pms.de" class="ste-ctcts-contact-action">
                <svg data-v-002fd7de="" xmlns="http://www.w3.org/2000/svg" width="18.224" height="13.5" viewBox="0 0 18.224 13.5"><g data-v-002fd7de="" id="Gruppe_9" data-name="Gruppe 9" transform="translate(-930.973 -410.25)" opacity="0.603"><rect data-v-002fd7de="" id="Rechteck_15" data-name="Rechteck 15" width="16" height="12" rx="2.5" transform="translate(932 411)" fill="none" stroke="#3a3a3c" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"></rect><path data-v-002fd7de="" id="Pfad_4" data-name="Pfad 4" d="M619,61l7.68,6.583a.575.575,0,0,0,.749,0L635.106,61" transform="translate(313.033 351.279)" fill="none" stroke="#3a3a3c" stroke-linejoin="round" stroke-width="1.5"></path><line data-v-002fd7de="" id="Linie_1" data-name="Linie 1" x1="5.707" y2="4.89" transform="translate(932.03 417.168)" fill="none" stroke="#3a3a3c" stroke-linejoin="round" stroke-width="1.5"></line><line data-v-002fd7de="" id="Linie_2" data-name="Linie 2" x1="5.707" y1="4.89" transform="translate(942.432 417.168)" fill="none" stroke="#3a3a3c" stroke-linejoin="round" stroke-width="1.5"></line></g></svg>
                info@fgb-pms.de
              </a>
              <a target="_blank" href="https://www.google.de/maps/place/FGB:+Pr%C3%A4zisionsmaschinenbau+Suhl+Steinbach+GmbH+%26+Co.+KG/@50.6493315,10.6810006,1113m/data=!3m2!1e3!4b1!4m6!3m5!1s0x47a39a62104a3a35:0x556f6b19046bf21a!8m2!3d50.6493281!4d10.6835809!16s%2Fg%2F11h64_hxfm?entry=ttu&g_ep=EgoyMDI1MDEyMi4wIKXMDSoASAFQAw%3D%3D" class="ste-ctcts-contact-action">
                <svg xmlns="http://www.w3.org/2000/svg" width="16.14" height="19.739" viewBox="0 0 16.14 19.739">
                  <rect id="Rechteck_1" data-name="Rechteck 1" width="16.14" height="18" transform="translate(0 0.398)" fill="#fff" opacity="0"/>
                  <g id="Gruppe_1" data-name="Gruppe 1" transform="translate(0.5 -0.464)">
                    <path id="Pfad_1" data-name="Pfad 1" d="M7.759,1.418A6.343,6.343,0,0,0,2.2,10.805l4.751,8.378a.935.935,0,0,0,1.626,0l4.758-8.392A6.343,6.343,0,0,0,7.759,1.418Zm0,3.075A3.267,3.267,0,1,1,4.492,7.76,3.271,3.271,0,0,1,7.759,4.493Z" transform="translate(-0.204 -0.204)" fill="none" stroke="#878788" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
                  </g>
                </svg>
                Standort in Google Maps öffnen
              </a>
            </div>
            <div v-if="address === 106" class="ste-ctcts-comp-address">
              <div class="ste-ctcts-contact-name">
                <strong>Seger Recycling und Transporte GmbH & Co. KG</strong><br>
                Strahlunger Straße 18<br>
                97616 Salz<br>
              </div><br>
              <a href="tel:+49977168877400" class="ste-ctcts-contact-action">
                <svg data-v-002fd7de="" xmlns="http://www.w3.org/2000/svg" width="16.462" height="16.463" viewBox="0 0 16.462 16.463"><path data-v-002fd7de="" id="Pfad_3" data-name="Pfad 3" d="M23.951,26.936a5.977,5.977,0,0,0,3.007-3.007,5.758,5.758,0,0,0-3.578-2.982,9.55,9.55,0,0,1-1.789,1.789,5.368,5.368,0,0,1-5.368-5.368,9.533,9.533,0,0,1,1.789-1.789A5.758,5.758,0,0,0,15.03,12a5.978,5.978,0,0,0-3.007,3.007,11.246,11.246,0,0,0,11.929,11.93Z" transform="translate(-11.246 -11.25)" fill="none" stroke="#3a3a3c" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" opacity="0.603"></path></svg>
                +49 (0) 9771 68877-400
              </a>
              <a href="mailto:entsorgung@steinbach-gruppe.de" class="ste-ctcts-contact-action">
                <svg data-v-002fd7de="" xmlns="http://www.w3.org/2000/svg" width="18.224" height="13.5" viewBox="0 0 18.224 13.5"><g data-v-002fd7de="" id="Gruppe_9" data-name="Gruppe 9" transform="translate(-930.973 -410.25)" opacity="0.603"><rect data-v-002fd7de="" id="Rechteck_15" data-name="Rechteck 15" width="16" height="12" rx="2.5" transform="translate(932 411)" fill="none" stroke="#3a3a3c" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"></rect><path data-v-002fd7de="" id="Pfad_4" data-name="Pfad 4" d="M619,61l7.68,6.583a.575.575,0,0,0,.749,0L635.106,61" transform="translate(313.033 351.279)" fill="none" stroke="#3a3a3c" stroke-linejoin="round" stroke-width="1.5"></path><line data-v-002fd7de="" id="Linie_1" data-name="Linie 1" x1="5.707" y2="4.89" transform="translate(932.03 417.168)" fill="none" stroke="#3a3a3c" stroke-linejoin="round" stroke-width="1.5"></line><line data-v-002fd7de="" id="Linie_2" data-name="Linie 2" x1="5.707" y1="4.89" transform="translate(942.432 417.168)" fill="none" stroke="#3a3a3c" stroke-linejoin="round" stroke-width="1.5"></line></g></svg>
                entsorgung@steinbach-gruppe.de
              </a>
              <a target="_blank" href="https://www.google.de/maps/place/Seger+Recycling+und+Transporte+GmbH+%26+Co.+KG+%7C+Standort+Salz%2FStrahlungen/@50.2939607,10.209211,4051m/data=!3m2!1e3!5s0x47a303e8181a2ebd:0xf39680a681c8f7c1!4m10!1m2!2m1!1sSeger+Recycling+und+Transporte+GmbH+%26+Co.+KG!3m6!1s0x47a3037d8950c3ff:0x7907fec33881735f!8m2!3d50.292432!4d10.2230039!15sCixTZWdlciBSZWN5Y2xpbmcgdW5kIFRyYW5zcG9ydGUgR21iSCAmIENvLiBLR5IBGHdhc3RlX21hbmFnZW1lbnRfc2VydmljZeABAA!16s%2Fg%2F11v9_9zt_9?entry=ttu&g_ep=EgoyMDI1MDEyMi4wIKXMDSoASAFQAw%3D%3D" class="ste-ctcts-contact-action">
                <svg xmlns="http://www.w3.org/2000/svg" width="16.14" height="19.739" viewBox="0 0 16.14 19.739">
                  <rect id="Rechteck_1" data-name="Rechteck 1" width="16.14" height="18" transform="translate(0 0.398)" fill="#fff" opacity="0"/>
                  <g id="Gruppe_1" data-name="Gruppe 1" transform="translate(0.5 -0.464)">
                    <path id="Pfad_1" data-name="Pfad 1" d="M7.759,1.418A6.343,6.343,0,0,0,2.2,10.805l4.751,8.378a.935.935,0,0,0,1.626,0l4.758-8.392A6.343,6.343,0,0,0,7.759,1.418Zm0,3.075A3.267,3.267,0,1,1,4.492,7.76,3.271,3.271,0,0,1,7.759,4.493Z" transform="translate(-0.204 -0.204)" fill="none" stroke="#878788" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
                  </g>
                </svg>
                Standort in Google Maps öffnen
              </a>
            </div>
            <div v-if="address === 109" class="ste-ctcts-comp-address">
              <div class="ste-ctcts-contact-name">
                <strong>SST Steinindustrie, Straßen- und Tiefbau GmbH & Co. KG</strong><br>
                Hauptstraße 22<br>
                98547 Schwarza<br>
              </div><br>
              <a href="tel:+49368437960" class="ste-ctcts-contact-action">
                <svg data-v-002fd7de="" xmlns="http://www.w3.org/2000/svg" width="16.462" height="16.463" viewBox="0 0 16.462 16.463"><path data-v-002fd7de="" id="Pfad_3" data-name="Pfad 3" d="M23.951,26.936a5.977,5.977,0,0,0,3.007-3.007,5.758,5.758,0,0,0-3.578-2.982,9.55,9.55,0,0,1-1.789,1.789,5.368,5.368,0,0,1-5.368-5.368,9.533,9.533,0,0,1,1.789-1.789A5.758,5.758,0,0,0,15.03,12a5.978,5.978,0,0,0-3.007,3.007,11.246,11.246,0,0,0,11.929,11.93Z" transform="translate(-11.246 -11.25)" fill="none" stroke="#3a3a3c" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" opacity="0.603"></path></svg>
                +49 (0) 36843 796-0
              </a>
              <a href="mailto:sst.thueringen@steinbach-gruppe.de" class="ste-ctcts-contact-action">
                <svg data-v-002fd7de="" xmlns="http://www.w3.org/2000/svg" width="18.224" height="13.5" viewBox="0 0 18.224 13.5"><g data-v-002fd7de="" id="Gruppe_9" data-name="Gruppe 9" transform="translate(-930.973 -410.25)" opacity="0.603"><rect data-v-002fd7de="" id="Rechteck_15" data-name="Rechteck 15" width="16" height="12" rx="2.5" transform="translate(932 411)" fill="none" stroke="#3a3a3c" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"></rect><path data-v-002fd7de="" id="Pfad_4" data-name="Pfad 4" d="M619,61l7.68,6.583a.575.575,0,0,0,.749,0L635.106,61" transform="translate(313.033 351.279)" fill="none" stroke="#3a3a3c" stroke-linejoin="round" stroke-width="1.5"></path><line data-v-002fd7de="" id="Linie_1" data-name="Linie 1" x1="5.707" y2="4.89" transform="translate(932.03 417.168)" fill="none" stroke="#3a3a3c" stroke-linejoin="round" stroke-width="1.5"></line><line data-v-002fd7de="" id="Linie_2" data-name="Linie 2" x1="5.707" y1="4.89" transform="translate(942.432 417.168)" fill="none" stroke="#3a3a3c" stroke-linejoin="round" stroke-width="1.5"></line></g></svg>
                sst.thueringen@steinbach-gruppe.de
              </a>
              <a target="_blank" href="https://www.google.de/maps/place/SST+Steinindustrie,+Stra%C3%9Fen-+und+Tiefbau+GmbH+%26+Co.+KG/@50.6260934,10.5311597,1113m/data=!3m2!1e3!4b1!4m6!3m5!1s0x47a39c2c477060db:0xc06fc45470250aca!8m2!3d50.62609!4d10.53374!16s%2Fg%2F1tff20v3?entry=ttu&g_ep=EgoyMDI1MDEyMi4wIKXMDSoASAFQAw%3D%3D" class="ste-ctcts-contact-action">
                <svg xmlns="http://www.w3.org/2000/svg" width="16.14" height="19.739" viewBox="0 0 16.14 19.739">
                  <rect id="Rechteck_1" data-name="Rechteck 1" width="16.14" height="18" transform="translate(0 0.398)" fill="#fff" opacity="0"/>
                  <g id="Gruppe_1" data-name="Gruppe 1" transform="translate(0.5 -0.464)">
                    <path id="Pfad_1" data-name="Pfad 1" d="M7.759,1.418A6.343,6.343,0,0,0,2.2,10.805l4.751,8.378a.935.935,0,0,0,1.626,0l4.758-8.392A6.343,6.343,0,0,0,7.759,1.418Zm0,3.075A3.267,3.267,0,1,1,4.492,7.76,3.271,3.271,0,0,1,7.759,4.493Z" transform="translate(-0.204 -0.204)" fill="none" stroke="#878788" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
                  </g>
                </svg>
                Standort in Google Maps öffnen
              </a>
            </div>
            <div v-if="address === 105" class="ste-ctcts-comp-address">
              <div class="ste-ctcts-contact-name">
                <strong>SST Straßen- und Tiefbau GmbH & Co. KG</strong><br>
                Strahlunger Straße 18<br>
                97616 Salz<br>
              </div><br>
              <a href="tel:+49977168877300" class="ste-ctcts-contact-action">
                <svg data-v-002fd7de="" xmlns="http://www.w3.org/2000/svg" width="16.462" height="16.463" viewBox="0 0 16.462 16.463"><path data-v-002fd7de="" id="Pfad_3" data-name="Pfad 3" d="M23.951,26.936a5.977,5.977,0,0,0,3.007-3.007,5.758,5.758,0,0,0-3.578-2.982,9.55,9.55,0,0,1-1.789,1.789,5.368,5.368,0,0,1-5.368-5.368,9.533,9.533,0,0,1,1.789-1.789A5.758,5.758,0,0,0,15.03,12a5.978,5.978,0,0,0-3.007,3.007,11.246,11.246,0,0,0,11.929,11.93Z" transform="translate(-11.246 -11.25)" fill="none" stroke="#3a3a3c" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" opacity="0.603"></path></svg>
                +49 (0) 9771 68877-300
              </a>
              <a href="mailto:sst.bayern@steinbach-gruppe.de" class="ste-ctcts-contact-action">
                <svg data-v-002fd7de="" xmlns="http://www.w3.org/2000/svg" width="18.224" height="13.5" viewBox="0 0 18.224 13.5"><g data-v-002fd7de="" id="Gruppe_9" data-name="Gruppe 9" transform="translate(-930.973 -410.25)" opacity="0.603"><rect data-v-002fd7de="" id="Rechteck_15" data-name="Rechteck 15" width="16" height="12" rx="2.5" transform="translate(932 411)" fill="none" stroke="#3a3a3c" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"></rect><path data-v-002fd7de="" id="Pfad_4" data-name="Pfad 4" d="M619,61l7.68,6.583a.575.575,0,0,0,.749,0L635.106,61" transform="translate(313.033 351.279)" fill="none" stroke="#3a3a3c" stroke-linejoin="round" stroke-width="1.5"></path><line data-v-002fd7de="" id="Linie_1" data-name="Linie 1" x1="5.707" y2="4.89" transform="translate(932.03 417.168)" fill="none" stroke="#3a3a3c" stroke-linejoin="round" stroke-width="1.5"></line><line data-v-002fd7de="" id="Linie_2" data-name="Linie 2" x1="5.707" y1="4.89" transform="translate(942.432 417.168)" fill="none" stroke="#3a3a3c" stroke-linejoin="round" stroke-width="1.5"></line></g></svg>
                sst.bayern@steinbach-gruppe.de
              </a>
              <a target="_blank" href="https://www.google.de/maps/place/SST+Stra%C3%9Fen-+und+Tiefbau+GmbH+%26+Co.+KG/@50.2920815,10.2203381,1121m/data=!3m3!1e3!4b1!5s0x47a303e8181a2ebd:0xf39680a681c8f7c1!4m6!3m5!1s0x47a3038f04d3b453:0x953b05528dae3e4b!8m2!3d50.2920781!4d10.2229184!16s%2Fg%2F11b5pj_181?entry=ttu&g_ep=EgoyMDI1MDEyMi4wIKXMDSoASAFQAw%3D%3D" class="ste-ctcts-contact-action">
                <svg xmlns="http://www.w3.org/2000/svg" width="16.14" height="19.739" viewBox="0 0 16.14 19.739">
                  <rect id="Rechteck_1" data-name="Rechteck 1" width="16.14" height="18" transform="translate(0 0.398)" fill="#fff" opacity="0"/>
                  <g id="Gruppe_1" data-name="Gruppe 1" transform="translate(0.5 -0.464)">
                    <path id="Pfad_1" data-name="Pfad 1" d="M7.759,1.418A6.343,6.343,0,0,0,2.2,10.805l4.751,8.378a.935.935,0,0,0,1.626,0l4.758-8.392A6.343,6.343,0,0,0,7.759,1.418Zm0,3.075A3.267,3.267,0,1,1,4.492,7.76,3.271,3.271,0,0,1,7.759,4.493Z" transform="translate(-0.204 -0.204)" fill="none" stroke="#878788" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
                  </g>
                </svg>
                Standort in Google Maps öffnen
              </a>
            </div>
            <div v-if="address === 110" class="ste-ctcts-comp-address">
              <div class="ste-ctcts-contact-name">
                <strong>Steinbach Verwaltungsgesellschaft mbH</strong><br>
                Strahlunger Straße 18<br>
                97616 Salz<br>
              </div><br>
              <a href="tel:+49977168877230" class="ste-ctcts-contact-action">
                <svg data-v-002fd7de="" xmlns="http://www.w3.org/2000/svg" width="16.462" height="16.463" viewBox="0 0 16.462 16.463"><path data-v-002fd7de="" id="Pfad_3" data-name="Pfad 3" d="M23.951,26.936a5.977,5.977,0,0,0,3.007-3.007,5.758,5.758,0,0,0-3.578-2.982,9.55,9.55,0,0,1-1.789,1.789,5.368,5.368,0,0,1-5.368-5.368,9.533,9.533,0,0,1,1.789-1.789A5.758,5.758,0,0,0,15.03,12a5.978,5.978,0,0,0-3.007,3.007,11.246,11.246,0,0,0,11.929,11.93Z" transform="translate(-11.246 -11.25)" fill="none" stroke="#3a3a3c" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" opacity="0.603"></path></svg>
                +49 (0) 9771 68877-230
              </a>
              <a href="mailto:emporium@steinbach-gruppe.de" class="ste-ctcts-contact-action">
                <svg data-v-002fd7de="" xmlns="http://www.w3.org/2000/svg" width="18.224" height="13.5" viewBox="0 0 18.224 13.5"><g data-v-002fd7de="" id="Gruppe_9" data-name="Gruppe 9" transform="translate(-930.973 -410.25)" opacity="0.603"><rect data-v-002fd7de="" id="Rechteck_15" data-name="Rechteck 15" width="16" height="12" rx="2.5" transform="translate(932 411)" fill="none" stroke="#3a3a3c" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"></rect><path data-v-002fd7de="" id="Pfad_4" data-name="Pfad 4" d="M619,61l7.68,6.583a.575.575,0,0,0,.749,0L635.106,61" transform="translate(313.033 351.279)" fill="none" stroke="#3a3a3c" stroke-linejoin="round" stroke-width="1.5"></path><line data-v-002fd7de="" id="Linie_1" data-name="Linie 1" x1="5.707" y2="4.89" transform="translate(932.03 417.168)" fill="none" stroke="#3a3a3c" stroke-linejoin="round" stroke-width="1.5"></line><line data-v-002fd7de="" id="Linie_2" data-name="Linie 2" x1="5.707" y1="4.89" transform="translate(942.432 417.168)" fill="none" stroke="#3a3a3c" stroke-linejoin="round" stroke-width="1.5"></line></g></svg>
                emporium@steinbach-gruppe.de
              </a>
              <a target="_blank" href="https://www.google.com/maps/place/Steinbach-Verwaltungsgesellschaft+mbH/@50.2923035,10.2211775,739m/data=!3m1!1e3!4m10!1m2!2m1!1ssteinbach+verwaltungsgesellschaft+mbh!3m6!1s0x223ae5b602535c41:0xec1ff8b410d5937f!8m2!3d50.2926413!4d10.2219897!15sCiVzdGVpbmJhY2ggdmVyd2FsdHVuZ3NnZXNlbGxzY2hhZnQgbWJokgEUY29uc3RydWN0aW9uX2NvbXBhbnngAQA!16s%2Fg%2F11l_2xp8fk?entry=ttu&g_ep=EgoyMDI1MDIxMC4wIKXMDSoASAFQAw%3D%3D" class="ste-ctcts-contact-action">
                <svg xmlns="http://www.w3.org/2000/svg" width="16.14" height="19.739" viewBox="0 0 16.14 19.739">
                  <rect id="Rechteck_1" data-name="Rechteck 1" width="16.14" height="18" transform="translate(0 0.398)" fill="#fff" opacity="0"/>
                  <g id="Gruppe_1" data-name="Gruppe 1" transform="translate(0.5 -0.464)">
                    <path id="Pfad_1" data-name="Pfad 1" d="M7.759,1.418A6.343,6.343,0,0,0,2.2,10.805l4.751,8.378a.935.935,0,0,0,1.626,0l4.758-8.392A6.343,6.343,0,0,0,7.759,1.418Zm0,3.075A3.267,3.267,0,1,1,4.492,7.76,3.271,3.271,0,0,1,7.759,4.493Z" transform="translate(-0.204 -0.204)" fill="none" stroke="#878788" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
                  </g>
                </svg>
                Standort in Google Maps öffnen
              </a>
            </div>
            <ContactList :contacts="filteredContacts" ></ContactList>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import AXIOS from 'axios';
import Loader from 'vue-spinner/src/ClipLoader.vue'
import ContactList from './ContactList.vue'
export default {
  name: 'ContactSidebar',
  components: {
    Loader,
    ContactList
  },
  data() {
    return {
      isActive: false,
      contactsList: null,
      selectedFilters: null,
      selectedColumn: null,
      filterList: null,
      tempSubFilters: null,
      activePage: 1,
      firstFetch: true,
      contactsIsLoading: false,
      pageType: 'categories',
      searchQuery: '',
      searchableFields: ['firstname', 'lastname', 'additional_information', 'mails', 'phone_numbers'],
      searchedContacts: null,
      allowedCategories: ["Abteilung", "Baustoffe", "Sondermaschinenbau", "Bauwesen", "Entsorgung", "Energie und Automation", "Firma"],
      address: ''
    }
  },
  computed: {
    filteredCategories() {
      return this.filterList.filters.filter(item => this.allowedCategories.includes(item.text));
    },
    filteredContacts() {
      const selectedId = this.selectedFilters;
      if (!selectedId) {
        return this.contactsList.slice().sort((a, b) => {
          const lastNameComparison = a.lastname.localeCompare(b.lastname);
          return lastNameComparison !== 0 ? lastNameComparison : a.firstname.localeCompare(b.firstname);
        });
      }
      return this.contactsList
          .filter(contact => {
            return Object.keys(contact).some(filterKey => {
              if (filterKey.startsWith('filter_')) {
                const contactFilters = contact[filterKey] || [];
                return contactFilters.some(cf => cf.id === selectedId);
              }
              return false;
            });
          })
          .sort((a, b) => {
            const lastNameComparison = a.lastname.localeCompare(b.lastname);
            return lastNameComparison !== 0 ? lastNameComparison : a.firstname.localeCompare(b.firstname);
          });
    }
  },
  methods: {
    searchContact () {
      this.pageType = 'search'
      this.searchedContacts = this.searchContacts()
      this.$nextTick(() => {
        this.activePage = 2
      });
    },
    searchContacts() {
      if (!this.searchQuery.trim()) {
        return this.contactsList;
      }
      // Suchanfrage in Wörter aufteilen
      const queries = this.searchQuery.toLowerCase().split(/\s+/);
      return this.contactsList.filter(contact => {
        // Prüfen, ob alle Wörter in mindestens einem definierten Feld gefunden werden
        return queries.every(query => {
          return this.searchableFields.some(field => {
            if (Array.isArray(contact[field])) {
              // Wenn das Feld ein Array ist (z. B. `mails` oder `phone_numbers`)
              return contact[field].some(item =>
                  Object.values(item).some(value =>
                      String(value).toLowerCase().includes(query)
                  )
              )
            } else if (typeof contact[field] === 'string') {
              // Wenn das Feld ein String ist (z. B. `firstname`, `lastname`)
              return contact[field].toLowerCase().includes(query);
            }
            return false;
          })
        })
      })
    },
    viewSubCategories (sub_filters) {
      this.tempSubFilters = sub_filters
      this.pageType = 'categories'
      this.activePage = 2
    },
    viewFilteredContacts (filterId) {
      // console.log('filterId', filterId)
      this.address = filterId
      this.selectedFilters = filterId
      this.activePage = 3
    },
    closeSidebar () {
      this.$emit('child-clicked')
      this.isActive = false
    },
    async loadContacts () {
      this.isActive = true
      if (this.firstFetch) {
        this.contactsIsLoading = true
        let params = {}
        params['module_division_id'] = 198
        AXIOS.post('https://zips-api.steinbach-gruppe.de/api/modules/open-contacts/fetch-contacts', params).then((response) => {
          if (response.status === 200) {
            this.firstFetch = false
            this.contactsIsLoading = false
            this.contactsList = response.data.data
            this.filterList = response.data.module_division_setup
          } else {
            console.log('error')
            this.contactsIsLoading = false
          }
        }).catch(() => {
          this.contactsIsLoading = false
        })
      }
    }
  }
}
</script>

<style scoped>
.ste-ctcts-header {
  width: 100%;
  height: 80px;
  background-color: #f2f2f2;
}
.ste-ctcts-main {
  height: calc(100% - 80px);
  width: 100%;
}
.ste-ctcts-main-frame {
  height: 100%;
  width: 100%;
  overflow: hidden;
  display: flex;
  flex-wrap: nowrap;
  font-size: 15px;
}

.ste-ctcts-close-btn {
  display: flex;
  cursor: pointer;
  font-size: 16px;
  font-family: Avenir Black, sans-serif;
  text-transform: uppercase;
  align-items: center;
  padding: 15px 25px 15px 20px;
  box-sizing: border-box;
  transition: opacity 0.2s ease-out;
}
.ste-ctcts-close-btn:hover {
  opacity: 0.7;
}

.ste-ctcts-filterpage {
  width: 100%;
  min-width: 100%;
  height: 100%;
  overflow: scroll;
  background-color: #ffffff;
  padding: 30px;
  box-sizing: border-box;
  transition: transform 0.2s ease-out;
}
.oneLength {
  transform: translateX(-100%)
}
.twoLength {
  transform: translateX(-200%)
}
.ste-ctcts-filter-items {
  width: 100%;
  background-color: #EDEDED;
  font-family: Avenir Black, sans-serif;
  margin-bottom: 10px;
  color: #4C4C4C;
  display: flex;
  align-items: center;
  border-left: 3px solid #0074BC;
  padding: 15px 20px;
  box-sizing: border-box;
  cursor: pointer;
  transition: background-color 0.2s ease-out;
}
.ste-ctcts-filter-items.no-show {
  display: none;
}
.ste-ctcts-filter-items.space {
  margin-bottom: 40px;
}
.ste-ctcts-filter-items:hover {
  background-color: #dcdcdc;
  color: #333333;
}
.ste-ctcts-filter-items > span {
  flex: 1 1 auto;
  text-align: left;
}
.ste-ctcts-filtersubpage {
  width: 100%;
  min-width: 100%;
  height: 100%;
  overflow: scroll;
  background-color: #ffffff;
  padding: 30px;
  box-sizing: border-box;
  transition: transform 0.2s ease-out;
}
.ste-ctcts-entries {
  width: 100%;
  min-width: 100%;
  height: 100%;
  overflow: scroll;
  background-color: #ffffff;
  padding: 30px;
  box-sizing: border-box;
  transition: transform 0.2s ease-out;
}
.ste-ctcts-back-btn {
  width: fit-content;
  background-color: #EDEDED;
  font-family: Avenir Black, sans-serif;
  margin-bottom: 20px;
  color: #4C4C4C;
  display: flex;
  align-items: center;
  padding: 15px 25px 15px 20px;
  box-sizing: border-box;
  cursor: pointer;
  transition: background-color 0.2s ease-out;
}
.ste-ctcts-back-btn:hover {
  background-color: #dcdcdc;
  color: #333333;
}
.ste-ctcts-back-btn > svg {
  margin-right: 15px;
  transform: rotate(180deg);
}
.ste-ctcts-loader {
  margin: auto;
  margin-top: 150px;
}
.ctcts-fade-enter-active, .ctcts-fade-leave-active {
  transition: opacity 0.3s;
}
.ctcts-fade-enter, .ctcts-fade-leave-to {
  opacity: 0;
}
.ste-ctcts-search-holder {
  display: flex;
}
.ste-ctcts-search-field {
  margin-right: 10px !important;
}
.search-btn {
  background-color: #0074BC;
  color: #FFFFFF;
  height: 48px;
  width: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Avenir Black, sans-serif;
  user-select: none;
  cursor: pointer;
  transition: opacity 0.2s ease-out;
}
.search-btn:hover {
  opacity: 0.8;
}

.search-btn.disabled {
  opacity: 0.25;
  user-select: none;
  pointer-events: none;
}
.ste-ctcts-filtersubpage.no-show {
  opacity: 0;
  z-index: -1;
  height: 0;
  width: 0;
  min-width: 0;
  padding: 0;
}
.ste-ctcts-hl {
  color: #4C4C4C;
  font-size: 16px;
  font-family: Avenir Black, sans-serif;
  margin-bottom: 9px;
  text-align: left;
}
@media only screen and (max-width: 499px) {
  .ste-ctcts-filterpage {
    padding: 15px;
  }
  .ste-ctcts-filtersubpage {
    padding: 15px;
  }
  .ste-ctcts-entries {
    padding: 15px;
  }
}
.ste-ctcts-comp-address {
  text-align: left;
  margin-top: 40px;
}
.ste-ctcts-contact-name {
  padding: 0 15px;
}
</style>


<style>
.ste-ctcts-main .v-text-field.v-text-field--solo > .v-input__control > .v-input__slot {
  box-shadow: none !important;
  background-color: #FAFAFA !important;
  border: 2px solid #D6D6D6 !important;
  transition: border 0.2s ease-out;
}
.ste-ctcts-main .v-text-field.v-text-field--solo.v-input--is-focused > .v-input__control > .v-input__slot {
  border: 2px solid #a3a3a3 !important;
}
</style>